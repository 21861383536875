import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Input, Typography, Space } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';
import { error } from '@guuru/react-message';
import { Field, Form, useForm } from '@guuru/react-forms';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import UPDATE_EXPERT_PARTNER_BIO from './mutations/updatePartnerBio';

const { TextArea } = Input;
const { Text, Link } = Typography;

const AboutForm = function ({ partnerId, name, bio = null }) {
  const { id: expertId } = useCurrentUser();
  const [editMode, setEditMode] = useState(false);
  const [form] = useForm();
  const [updateBio] = useMutation(UPDATE_EXPERT_PARTNER_BIO);

  const onFinish = async (values) => {
    setEditMode(false);
    await updateBio({
      variables: {
        expertId,
        partnerId,
        bio: values.bio,
      },
    }).catch((err) => error(err.message));
  };

  return (
    <Form
      form={form}
      onSave={onFinish}
      savingMessage="Updating Expert Bio..."
      savedMessage="Expert Bio updated"
      initialValues={{ bio }}
    >
      <Field
        name="bio"
        label={(
          <Space>
            <SolutionOutlined />
            <Text>Bio</Text>
          </Space>
        )}
        rules={[
          {
            required: true,
            message: 'Please input your Bio',
          },
        ]}
        extra={!bio ? (
          <>
            <Text type="secondary">Don&apos;t know what to write? Find some inspiration </Text>
            <Link
              target="_blank"
              href={`${process.env.PAGES_URL}/${partnerId}/articles/manage-profile`}
            > here.
            </Link>
          </>
        ) : undefined}
      >
        <TextArea
          maxLength={250}
          autoFocus={editMode}
          autoSize
          showCount={editMode}
          placeholder={`Tell us what makes you a good ${name} Guuru?`}
          onFocus={() => setEditMode(true)}
          onBlur={() => setEditMode(false)}
        />
      </Field>

    </Form>
  );
};

AboutForm.propTypes = {
  partnerId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bio: PropTypes.string,
};

export default AboutForm;
