import styled from 'styled-components';
import { Table } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const PayoutTable = styled(Table)`
  .ant-table-small .ant-table-thead > tr > th {
    background-color: inherit;
  }

  .ant-table-cell {
    text-wrap: balance;
  }
`;
