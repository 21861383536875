import React, { useMemo } from 'react';
import { Table } from 'antd';
import Balance from '@guuru/react-balance';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { PayoutTable } from './style';

const productName = (item) => {
  if (!item?.product) {
    return '';
  }

  const { product, status, balances } = item;
  if (product !== 'payments') {
    return startCase(product);
  }

  const isDebit = balances.find(({ amount }) => amount < 0);
  if (!isDebit) {
    return 'Carried over from previous months or returned';
  }

  if (status === 'delayed') {
    return 'Carried forward to next month';
  }

  return 'Paid out';
};

const sum = (balances) => balances.reduce((acc, { amount }) => acc + amount, 0);

const Payout = function ({ statement = {} }) {
  const statements = useMemo(() => (
    [...statement?.node?.statements ?? []].sort((a, b) => (
      a.product.localeCompare(b.product) || sum(b.balances) - sum(a.balances)
    ))
  ), [statement?.node?.statements]);

  return (
    <PayoutTable
      rowKey="index"
      dataSource={statements}
      pagination={false}
      size="small"
    >
      <Table.Column
        title="Product"
        dataIndex="product"
        key="product"
        render={(text, node) => productName(node)}
      />
      <Table.Column
        title="Balance"
        align="right"
        dataIndex="balances"
        key="balances"
        ellipsis
        render={(text, node) => (
          <Balance balances={node?.balances} />
        )}
      />
    </PayoutTable>
  );
};

Payout.propTypes = {
  statement: PropTypes.shape({
    node: PropTypes.shape({
      period: PropTypes.number,
      statements: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default Payout;
